.courses-section {
  padding: 80px 20px;
  background-color: #1C1C1C;
  color: #ffffff;
}

.courses-section h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 60px;
  position: relative;
}

.courses-section h2::after {
  content: "";
  width: 60px;
  height: 4px;
  background-color: #3498DB;
  display: block;
  margin: 20px auto 0;
  border-radius: 2px;
}

.courses-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
  gap: 20px; 
  max-width: 1000px;
  margin: 0 auto;
}
.certificates-container {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.certificate-link {
  padding: 15px 30px;
  background-color: #3498DB;
  color: #ffffff;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.certificate-link:hover {
  background-color: #2980B9;
}


.course-item {
  padding: 20px;
  background-color: #000000;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.course-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.course-item h3 {
  font-size: 1.8rem;
  color: #ffffff;
  margin-bottom: 10px;
}

.course-item h4 {
  font-size: 1.2rem;
  color: #3498DB;
  margin-bottom: 10px;
}

.date {
  display: block;
  font-size: 1rem;
  color: #cccccc;
  margin-bottom: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .courses-section h2 {
    font-size: 2rem;
  }

  .course-item h3 {
    font-size: 1.6rem;
  }

  .course-item h4 {
    font-size: 1rem;
  }
}
