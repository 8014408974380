
.content-box {
    position: relative;
    height: 100vh;
    overflow: hidden;
}
.language-selector {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
    overflow: hidden;
}

.flag-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    padding: 0;
    overflow: hidden;
}

.flag-icon {
    width: 60px;
    height: 40px;
    display: block;
    transition: transform 0.3s ease;
    overflow: hidden;
    border-radius: 5px;
}

.flag-button:hover  {
    transform: scale(1.1);
    overflow: hidden;
}


.main-section {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-left: 5%;
    text-align: left;
    overflow-x: hidden;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Alinha o conteúdo ao topo do contêiner */
    align-items: flex-start; /* Garante que todo o conteúdo seja alinhado à esquerda */
    overflow-x: hidden;
}

.greeting {
    font-size: 2rem; 
    margin-bottom: 1rem; 
    color: #fff;
    font-weight: 400; 
    overflow-x: hidden;
}

h2 {
    font-size: 3.5rem; 
    font-weight: 700; 
    line-height: 1.2; 
    color: #fff;
    margin: 0; 
    font-family: 'Blinker', sans-serif; 
    overflow-x: hidden;
}

.p1, .p2 {
    display: block; 
    margin: 0; 
    padding: 0; 
    overflow-x: hidden;
}

.scroll-down-indicator {
    position: absolute;
    bottom: 20px; 
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    cursor: pointer; 
    animation: pulse 2s infinite; 
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}


.scroll-down-indicator .arrow {
    width: 48px; 
    height: 48px;
    fill: none; 
    stroke: white; 
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
}

/* Animação de pulsar */
@keyframes pulse {
    0%, 100% {
        transform: translateY(0);
        opacity: 1;
    }
    50% {
        transform: translateY(10px);
        opacity: 0.7;
    }
}


@media (max-width: 1024px) {
    h2 {
        font-size: 3rem; 
    }

    .greeting {
        font-size: 1.2rem; 
    }

    .main-section {
        padding-left: 8%; 
    }
}

@media (max-width: 768px) {
    h2 {
        font-size: 2.5rem;
    }

    .greeting {
        font-size: 1rem; 
    }

    .main-section {
        padding-left: 10%; 
    }
}

@media (max-width: 480px) {
    h2 {
        font-size: 2rem; 
    }

    .greeting {
        font-size: 0.9rem; 
    }

    .main-section {
        padding-left: 5vw; 
    }
}
