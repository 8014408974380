/* Geral */
.projects-section {
    padding: 80px 20px;
    background-color: #000000; 
}
  
  .projects-section h2 {
    text-align: center;
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 60px;
    position: relative;
  }
  
  .projects-section h2::after {
    content: "";
    width: 60px;
    height: 4px;
    background-color: #3498DB;
    display: block;
    margin: 20px auto 0;
    border-radius: 2px;
  }

  .project-container {
    max-width: 1200px; 
    margin: 0 auto; 
    display: flex;
    align-items: center;
    justify-content: space-between; 
    margin-bottom: 80px;
    gap: 40px; 
}
  
  .project-container.reverse {
    flex-direction: row-reverse;
  }

  .project-video {
    max-width: 100%;  
    width: 560px;  
    height: auto; 
    margin: 0 auto; 
  }

.project-video iframe {
    width: 100%;
    height: 315px;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
  
.project-image img {
    width: 100%;
    max-width: 640px; 
    height: auto;
    object-fit: cover; 
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}
  
  .project-image img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .project-content {
    flex: 1;
    max-width: 600px; 
    margin: 100 auto; 
}
  
  .project-content h3 {
    font-size: 2rem;
    color: #3498DB;
    margin-bottom: 20px;
    text-align: center;
}
  
  .project-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 30px;
    text-align: justify;
  }
  
  .project-tech {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top:15px;
    color: #000000;
  }
  
  .tech-item {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 10px 15px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }
  
.project-content p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #d0d0d0;
    margin-bottom: 1px;
    
}
  
  .tech-item:hover {
    background-color: #BDC3C7;
  }
  
  .tech-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  @media (max-width: 768px) {
    .project-video {
      width: 100%;  
    }
  }
  /* Responsividade */
  @media (max-width: 992px) {
    .project-container {
      flex-direction: column;
      gap: 20px;
    }
    
    @media (max-width: 768px) {
        .project-container {
          flex-direction: column;
        }
      }
      
  
    .project-container.reverse {
      flex-direction: column;
    }
  
    .project-image,
    .project-content {
      width: 100%;
    }
  
    .project-content h3 {
      text-align: center;
    }
  
    .project-content p {
      text-align: justify;
    }
  
    .project-tech {
      justify-content: center;
    }
  }
  